import { LinearProgress } from '@mui/material'
import React from 'react'
import imgLivin from '../assets/livin_blc.png'

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'black',
        flexDirection: 'column',
        height: '100%',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center'
      }}
    >
      <img src={imgLivin} alt='Livin Astro' style={{ maxWidth: '50%' }} />
      <LinearProgress color='secondary' style={{ height: '1px', width: '90%' }} variant='indeterminate' />
    </div>
  )
}

export default Loading
