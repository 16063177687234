import { createTheme } from '@mui/material'

export const muiTheme = createTheme({
  typography: {
    fontFamily: 'Roboto Condensed'
  },
  palette: {
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#000000'
    }
  }
})
